import styled from "styled-components";

export const MyButton = styled.button`
    width: 250px;
    font-size: 1.8rem;
    border: none;
    border-radius: 4px;
    padding: 20px 60px;
    background: ${props => props.theme.primary};
    cursor: pointer;
    position: relative;
    transition: all 0.35s;
    outline: none;
    &:after {
        background: ${props => props.theme.secondary};
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        transition: all 0.35s;
        border-radius: 4px;
    }
    &:hover {
        color: ${props => props.theme.on_surface};
    }
    &:hover:after {
        width: 100%;
    }
    @media screen and (max-width: 768px){
        width: 200px;
        margin-bottom: 10px;
    }
`;

export const ButtonLabel = styled.a`
    position: relative;
    z-index: 2;
    color: ${props => props.theme.surface};
    text-decoration: none;
    font-size: 30px;
    @media screen and (max-width: 768px){
        font-size: 25px;
    }
    @media screen and (max-width: 600px){
        font-size: 20px;
    }
`;
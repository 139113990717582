import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Fira Sans', sans-serif;
        scroll-behavior: smooth;
    }

    body {
        background: ${props => props.theme.background};
        font-size: 14px;
        color: ${props => props.theme.on_surface};
    }
`;
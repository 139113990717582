import React from 'react'
import { AboutDiv, AboutContainer, AboutContent, AboutImgContainer, AboutImgCard } from './about.styles'

const About = () => {
  return (
    <AboutDiv id="about">
        <AboutContainer>
            <AboutImgContainer>
                <AboutImgCard></AboutImgCard>
            </AboutImgContainer>
            <AboutContent>
                <h1>¿Qué hacemos?</h1>
               <h2>Te brindamos clases virtuales y presenciales de distintas materias para que podás reforzar tus conocimientos y cumplir con tus deberes</h2>
               <p>Nuestra prioridad es tu aprendizaje y comodidad</p> 
            </AboutContent>
        </AboutContainer>
    </AboutDiv>
  )
}

export default About;
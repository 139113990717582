export const lightTheme = {
    primary: "#0d47a1",
    primary_variant: "#002171",
    secondary: "#e91e63",
    secondary_variant: "#c2185b",
    surface: "#f5f5f5",
    background: "#fff",
    on_surface: "#000",
    intro_gradient: { one: "#009FFF", two: "-webkit-linear-gradient(to right, #ec2F4B, #009FFF)", three: "linear-gradient(to right, #ec2F4B, #009FFF)"},
    about_h1_gradient: {one: "#4e54c8", two: "-webkit-linear-gradient(to top, #8f94fb, #4e54c8)", three: "linear-gradient(to top, #8f94fb, #4e54c8)"},
    about_h2_gradient: {one: "#bc4e9c", two: "-webkit-linear-gradient(to right, #f80759, #bc4e9c)", three: "linear-gradient(to right, #f80759, #bc4e9c)"},
    about_img_gradient: {one: "#00c6ff", two: "-webkit-linear-gradient(to right, #0072ff, #00c6ff)", three: "linear-gradient(to right, #0072ff, #00c6ff)"}
};

export const darkTheme = {
    primary: "#fff176",
    primary_variant: "#fbc02d",
    secondary: "#64b5f6",
    secondary_variant: "#1976d2",
    surface: "#212121",
    background: "#121212",
    on_surface: "#fff",
    intro_gradient: { one: "#FDC830", two: "-webkit-linear-gradient(to right, #F37335, #FDC830)", three: "linear-gradient(to right, #F37335, #FDC830)"},
    about_h1_gradient: {one: "#56CCF2", two: "-webkit-linear-gradient(to right, #2F80ED, #56CCF2)", three: "linear-gradient(to right, #2F80ED, #56CCF2)"},
    about_h2_gradient: {one: "#ee0979", two: "-webkit-linear-gradient(to right, #ff6a00, #ee0979)", three: "linear-gradient(to right, #ff6a00, #ee0979)"},
    about_img_gradient: {one: "#1488CC", two: "-webkit-linear-gradient(to right, #2B32B2, #1488CC)", three: "linear-gradient(to right, #2B32B2, #1488CC)"}
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    Link
  } from "react-router-dom";
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FooterContainer, FooterLinkItems, FooterLinks, FooterLinksWrapper, FooterLogo, SocialIconLink, SocialIcons, SocialMedia, SocialMediaWrap, WebsiteRights} from './footer.styles';

const Footer = () => {
  return (
    <FooterContainer>
        <FooterLinks>
            <FooterLinksWrapper>
                <FooterLinkItems>
                    <h2>Acerca de nosotros</h2>
                    <Link to="/terms">Términos y condiciones</Link>
                    <Link to="/policy">Política de privacidad</Link>
                </FooterLinkItems>
                <FooterLinkItems>
                    <h2>Contáctenos</h2>
                    <a href="mailto:geniustutoringgroup@gmail.com">Correo</a>
                    <a href="tel:+50683305139">Teléfono</a>
                </FooterLinkItems>
            </FooterLinksWrapper>
        </FooterLinks>
        <SocialMedia>
            <SocialMediaWrap>
                <div>
                    <FooterLogo>GENIUS</FooterLogo>
                </div>
                <WebsiteRights>Ⓒ GENIUS 2022. Derechos reservados</WebsiteRights>
                <SocialIcons>
                    <SocialIconLink><FontAwesomeIcon icon={faFacebook}/></SocialIconLink>
                    <SocialIconLink><FontAwesomeIcon icon={faInstagram}/></SocialIconLink>
                    <SocialIconLink><FontAwesomeIcon icon={faTwitter}/></SocialIconLink>
                </SocialIcons>
            </SocialMediaWrap>
        </SocialMedia>
    </FooterContainer>
  )
}

export default Footer;
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Home from './pages/home';
import Terms from './pages/terms';
import { useState } from 'react';
import Policy from './pages/policy';

function getLocalTheme() {
  let storageTheme = localStorage.getItem("theme");
  return storageTheme ? storageTheme : "light"
}

function setLocalTheme(theme) {
  localStorage.setItem("theme", theme);
}

function App() {

  const [theme, setTheme] = useState(getLocalTheme());

  const toogleTheme = () => {
    if (theme === "light") {
      setTheme("dark")
      setLocalTheme("dark")
    } else {
      setTheme("light")
      setLocalTheme("light")
    }
  }

  return (
    <Router>
      <Routes>
        <Route path="/terms" element={<Terms />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/" element={<Home theme={theme} toogleTheme={toogleTheme} />} />
      </Routes>
    </Router>
  );
}

export default App;
import styled from "styled-components";

export const FooterContainer = styled.div`
    background-color: ${props => props.theme.background};
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FooterLinks = styled.div`
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 820px) {
        padding-top: 2rem;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;
    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    & h2 {
        margin-bottom: 16px;
        color: ${props => props.theme.on_surface};
    }
    & a {
        color: ${props => props.theme.on_surface};
        text-decoration: none;
        margin-bottom: 0.5rem;
        transition: 0.3 ease-out;
    }
    & a:hover {
        color: ${props => props.theme.secondary};
        transition: 0.3 ease-out;
    }
    @media screen and (max-width: 480px){
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`;


export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
`;

export const SocialIconLink = styled.a`
    color: ${props => props.theme.on_surface};
    font-size: 24px;
    cursor: pointer;
    & svg {  
        color: ${props => props.theme.on_surface};
    }
    & svg:hover {
        color: ${props => props.theme.primary};
    }
`;

export const FooterLogo = styled.a`
    color: ${props => props.theme.on_surface};
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    @media screen and (max-width: 820px) {
        margin-bottom: 2rem; 
    }
`;

export const WebsiteRights = styled.p`
    color: ${props => props.theme.on_surface};
    @media screen and (max-width: 820px) {
        margin-bottom: 2rem;
    }
`;
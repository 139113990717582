import React from 'react'
import Button from '../../components/button/button';
import { ButtonContainer, IntroDiv, IntroContainer, IntroDescription, IntroHeading, IntroHeadingSpan } from './intro.styles'

const Intro = () => {
  return (
    <IntroDiv id="home">
        <IntroContainer>
            <IntroHeading>Aprendamos <IntroHeadingSpan>juntos</IntroHeadingSpan></IntroHeading>
            <IntroDescription>Descargá nuestra aplicación para poder acceder a nuestros servicios</IntroDescription>
            <ButtonContainer>
                <Button text="Android"/>
                <Button text="IOS"/>
            </ButtonContainer>
        </IntroContainer>
    </IntroDiv>
  )
}

export default Intro;
import styled from "styled-components";

export const Nav = styled.nav`
    background: ${props => props.theme.surface};
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
`;

export const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 50px;
    @media screen and (max-width: 768px){
        justify-content: center;
    }
`;

export const NavLogo = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 2rem;
    text-decoration: none;
    color: ${props => props.theme.primary};
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLink = styled.a`
    color: ${props => props.theme.on_surface};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    text-decoration: none;
    height: 100%;
    transition: all 0.3s ease;
    height: 100%;
    text-decoration: none;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    border-bottom: ${props => (props.selected) ? "0px" : `4px solid ${props.theme.primary}` };
    &:hover {
        color: ${props => props.theme.secondary};
        transition: all 0.3s ease;
    }
`;
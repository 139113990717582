import React from 'react'
import Header from '../sections/header/header';
import Intro from '../sections/intro/intro';
import About from '../sections/about/about';
import Bottom from '../sections/bottom/bottom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styles/global';
import { lightTheme, darkTheme } from '../styles/themes';


const Home = ({ theme, toogleTheme }) => {

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Header toogleTheme={toogleTheme} />
      <Intro />
      <About />
      <Bottom />
      </ThemeProvider>
  )
}
export default Home;
import styled from "styled-components";

export const AboutDiv = styled.div`
    background-color: ${props => props.theme.surface};
    padding: 10rem 0;
`;

export const AboutContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 90%;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    padding: 0 50px;
    @media screen and (max-width: 1100px) {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        height: 90%;
    }
`;

export const AboutContent = styled.div`
    color: ${props => props.theme.on_surface};
    width: 100%;
    & h1 {
        font-size: 2rem;
        background: ${props => props.theme.about_h1_gradient.one};
        background: ${props => props.theme.about_h1_gradient.two};
        background: ${props => props.theme.about_h1_gradient.three}; 
        background-size: 100%;
        -webkit-background-clip: text;
        -mox-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        text-transform: uppercase;
        margin-bottom: 32px;
    }
    & h2 {
        font-size: 4rem;
        background: ${props => props.theme.about_h2_gradient.one};
        background: ${props => props.theme.about_h2_gradient.two};
        background: ${props => props.theme.about_h2_gradient.three};
        background-size: 100%;
        -webkit-background-clip: text;
        -mox-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
    & p {
        margin-top: 1rem;
        font-size: 2rem;
        font-weight: 700;
    }
    @media screen and (max-width: 1100px) {
        text-align: center;
        margin-bottom: 4rem;
        & h1 {
            font-size: 2.5rem;
            margin-top: 2rem;
        }
        & h2 {
            font-size: 3rem;
        }

        & p {
            margin-top: 1rem;
            font-size: 1.5rem;
        }
    }
    @media screen and (max-width: 480px) {
        font-size: 2rem;
        margin-top: 3rem;

        & h2 {
            font-size: 2rem;
        }

        & p {
            margin-top: 2rem;
        }
    }
`;

export const AboutImgContainer = styled.div`
    text-align: center;
    @media screen and (max-width: 1100px) {
        display: flex;
        justify-content: center;
    }
`;

export const AboutImgCard = styled.div`
    margin: 10px;
    height: 500px;
    width: 500px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${props => props.theme.on_surface};
    background: ${props => props.theme.about_img_gradient.one};
    background: ${props => props.theme.about_img_gradient.two};
    background: ${props => props.theme.about_img_gradient.three};
    @media screen and (max-width: 1100px) {
        height: 425px;
        width: 425px;
    }
    @media screen and (max-width: 480px) {
        width: 250px;
        height: 250px;
    }
`;
import styled from "styled-components";

export const IntroDiv = styled.div`
    background: ${props => props.theme.background};
    padding: 170px 0;
    @media screen and (max-width: 768px){
        padding: 100px 0;
    }
`;

export const IntroContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto;
    height: 90%;
    text-align: center;
    padding: 30px;  
`;

export const IntroHeading = styled.h1`
    font-size: 100px;
    margin-bottom: 24px;
    color: ${props => props.theme.on_surface};
    @media screen and (max-width: 768px){
        font-size: 60px;
    }
    @media screen and (max-width: 600px){
        font-size: 55px;
    }
`;

export const IntroHeadingSpan = styled.span`
    background: ${props => props.theme.intro_gradient.one};
    background: ${props => props.theme.intro_gradient.two};
    background: ${props => props.theme.intro_gradient.three};
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
`;

export const IntroDescription = styled.p`
    font-size: 60px;
    color: ${props => props.theme.on_surface};
    @media screen and (max-width: 768px){
        font-size: 40px;
    }
    @media screen and (max-width: 600px){
        font-size: 35px;
    }
`;

export const ButtonContainer = styled.div`
    width: 90%;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media screen and (max-width: 768px){
        width: 100%;
        margin: 20px 40px;
        display: grid;
        justify-content: center;
        align-items: center;
    }
`;
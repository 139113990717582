import React, {useState} from 'react'
import { Nav, NavContainer, NavLogo, NavMenu, NavItem, NavLink } from './navbar.styles'

const Navbar = ({ toogleTheme }) => {

  const [scrollPos, setScrollPos] = useState(window.scrollY)

  window.addEventListener('scroll', function() { setScrollPos(this.scrollY) });
  window.addEventListener('click', function() { setScrollPos(this.scrollY) });

  return (
    <Nav>
      <NavContainer>
        <NavLogo onClick={toogleTheme}>GENIUS</NavLogo>
        <NavMenu>
          <NavItem><NavLink href='#home' id="home-page" selected={ !(scrollPos < 600) ? true : false }>Inicio</NavLink></NavItem>
          <NavItem><NavLink href='#about' id="about-page" selected={ !(scrollPos >= 600) ? true : false }>Nosotros</NavLink></NavItem>
        </NavMenu>
      </NavContainer>
    </Nav>
  )
}


export default Navbar;